import { createBrowserRouter } from 'react-router-dom';
import { Paths } from './paths';
import App from '../../App';
import ErrorPage from '../../../pages/ErrorPage/ErrorPage';
import { MainPage } from '../../../pages/MainPage';
import { SubwoofersPage } from '../../../pages/Subwoofers';
import { ProductPage } from '../../../pages/ProductPage';
import { CablesPage } from '../../../pages/CablesPage';
import {
  AcousticsCategoryPage,
  AcousticsPage
} from '../../../pages/AcousticsPage';
import {
  AmplifiersCategoryPage,
  AmplifiersPage
} from '../../../pages/AmplifiersPage';
import {
  AccessoriesLazy,
  CompareLazy,
  OverviewLazy,
  RelatedLazy,
  TechSpecsLazy
} from '../../../pages/ProductPage/NestedRoutes';
import { ProductPageNew } from '../../../pages/ProductPageNew';
import {
  AccessoriesLazyNew,
  CompareLazyNew,
  OverviewLazyNew,
  RelatedLazyNew,
  TechSpecsLazyNew
} from '../../../pages/ProductPageNew/NestedRoutes';
import { ProductPageNew2 } from '../../../pages/ProductPageNew2';
import {
  AccessoriesLazyNew2,
  CompareLazyNew2,
  OverviewLazyNew2,
  RelatedLazyNew2,
  TechSpecsLazyNew2
} from '../../../pages/ProductPageNew2/NestedRoutes';

export const router = createBrowserRouter([
  {
    path: Paths.MAIN,
    element: <App />, // Тут внутри Outlet
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            path: Paths.MAIN,
            element: <MainPage />,
            index: true
          },
          {
            path: Paths.SUBWOOFERS,
            element: <SubwoofersPage />
          },
          {
            path: Paths.SUBWOOFER_PRODUCT,
            element: <ProductPage />,
            children: [
              {
                path: Paths.PRODUCT_OVERVIEW,
                element: <OverviewLazy />,
                index: true
              },
              {
                path: Paths.PRODUCT_ACCESSORIES,
                element: <AccessoriesLazy />
              },
              {
                path: Paths.PRODUCT_RELATED,
                element: <RelatedLazy />
              },
              {
                path: Paths.PRODUCT_COMPARE,
                element: <CompareLazy />
              },
              {
                path: Paths.PRODUCT_TECHSPECS,
                element: <TechSpecsLazy />
              },
              {
                path: Paths.NOT_FOUND,
                element: <div>NOT_FOUND</div>
              }
            ]
          },
          {
            path: Paths.PRODUCT,
            element: <ProductPageNew />,
            children: [
              {
                path: Paths.PRODUCT_OVERVIEW,
                element: <OverviewLazyNew />,
                index: true
              },
              {
                path: Paths.PRODUCT_ACCESSORIES,
                element: <AccessoriesLazyNew />
              },
              {
                path: Paths.PRODUCT_RELATED,
                element: <RelatedLazyNew />
              },
              {
                path: Paths.PRODUCT_COMPARE,
                element: <CompareLazyNew />
              },
              {
                path: Paths.PRODUCT_TECHSPECS,
                element: <TechSpecsLazyNew />
              },
              {
                path: Paths.NOT_FOUND,
                element: <div>NOT_FOUND</div>
              }
            ]
          },
          {
            path: Paths.ACOUSTICS,
            element: <AcousticsPage />
          },
          {
            path: Paths.ACOUSTICS_CATEGORY,
            element: <AcousticsCategoryPage />
          },
          {
            path: Paths.AMPLIFIERS,
            element: <AmplifiersPage />
          },
          {
            path: Paths.AMPLIFIERS_CATEGORY,
            element: <AmplifiersCategoryPage />
          },
          {
            path: Paths.CABLES,
            element: <CablesPage />
          },
          {
            path: Paths.ACCESSORIES,
            element: <div>ACCESSORIES</div>
          },
          {
            path: Paths.CATALOG_SECTION,
            element: <AmplifiersPage />
          },
          {
            path: Paths.CATALOG_CATEGORY,
            element: <AmplifiersCategoryPage />
          },
          {
            path: Paths.CATALOG_MODEL,
            element: <ProductPageNew2 />,
            children: [
              {
                path: Paths.PRODUCT_OVERVIEW,
                element: <OverviewLazyNew2 />,
                index: true
              },
              {
                path: Paths.PRODUCT_ACCESSORIES,
                element: <AccessoriesLazyNew2 />
              },
              {
                path: Paths.PRODUCT_RELATED,
                element: <RelatedLazyNew2 />
              },
              {
                path: Paths.PRODUCT_COMPARE,
                element: <CompareLazyNew2 />
              },
              {
                path: Paths.PRODUCT_TECHSPECS,
                element: <TechSpecsLazyNew2 />
              },
              {
                path: Paths.NOT_FOUND,
                element: <div>NOT_FOUND</div>
              }
            ]
          },
          {
            path: Paths.NOT_FOUND,
            element: <div>NOT_FOUND</div>
          }
        ]
      }
    ]
  }
]);
