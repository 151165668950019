import React, { FC, ReactNode, Suspense } from 'react';

interface ILoaderLayout {
  children: ReactNode;
}

const LoaderLayout: FC<ILoaderLayout> = ({ children }) => {
  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>;
};

export default LoaderLayout;
