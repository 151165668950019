import M12Icon from '../assets/icons/subwoofers/m12.svg';
import { ItemProductType } from './subwoofersData';

export interface IAcousticsType {
  id: string;
  type: string;
  bannerData: BannerData;
  models: AcousticModel[];
}

export interface BannerData {
  title: string;
  text: string;
  image: Image;
  productTheme: 'white' | 'black';
}

export interface Image {
  small: string;
  medium: string;
}

export interface AcousticModel {
  name: string;
  bannerData: BannerData;
  techSpecs: ItemSpecsType[];
  physicalSpecs: ItemSpecsType[];
  photo: string[];
}

export interface ItemSpecsType {
  id: string;
  title: string;
  value: string;
}

export const newArrayAcoustics: IAcousticsType[] = [
  {
    id: '1',
    type: 'СЧ',
    bannerData: {
      title: 'СЧ',
      text: 'Ruby - clean and shiny',
      image: {
        small: '',
        medium: ''
      },
      productTheme: 'black'
    },
    models: [
      {
        name: 'Ruby Performance 6.5',
        bannerData: {
          title: 'Ruby Performance 6.5',
          text: 'Ruby - clean and shiny',
          image: {
            small: '',
            medium: ''
          },
          productTheme: 'black'
        },
        techSpecs: [
          {
            id: '0',
            title: 'Nominal impedance',
            value: '4 ohms'
          },
          {
            id: '1',
            title: 'Voice coil winding material',
            value: 'copper wire'
          },
          {
            id: '2',
            title: 'Effective radiation area',
            value: '144 cm²'
          },
          {
            id: '3',
            title: 'Power handling',
            value: '200 W (HPF 18 dB/oct. on 160 Hz)'
          },
          {
            id: '4',
            title: 'The range of frequencies to reproduce',
            value: '160Hz – 10kHz'
          },
          {
            id: '5',
            title: 'Magnet material',
            value: 'hybrid magnet'
          },
          {
            id: '6',
            title: 'Magnetic core material',
            value: 'electrical steel'
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '173.5 mm/6.83 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '151 mm/5.95 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '12.5 mm/0.50 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '70,5 mm/2.78 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '4.2 mm/0.17 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes',
            value: '163.5 mm/6.44 in'
          },
          {
            id: 'G',
            title: 'Orientation angle',
            value: '45°'
          }
        ],
        photo: []
      },
      {
        name: 'Ruby123',
        bannerData: {
          title: 'Ruby Performance 6.5123',
          text: 'some 111text banner',
          image: {
            small: '',
            medium: ''
          },
          productTheme: 'white'
        },
        techSpecs: [
          {
            id: '0',
            title: 'Nominal impedance',
            value: '4 ohms'
          },
          {
            id: '1',
            title: 'Voice coil winding material',
            value: 'copper wire'
          },
          {
            id: '2',
            title: 'Effective radiation area',
            value: '144 cm²'
          },
          {
            id: '3',
            title: 'Power handling',
            value: '200 W (HPF 18 dB/oct. on 160 Hz)'
          },
          {
            id: '4',
            title: 'The range of frequencies to reproduce',
            value: '160Hz – 10kHz'
          },
          {
            id: '5',
            title: 'Magnet material',
            value: 'hybrid magnet'
          },
          {
            id: '6',
            title: 'Magnetic core material',
            value: 'electrical steel'
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '173.5 mm/6.83 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '151 mm/5.95 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '12.5 mm/0.50 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '70,5 mm/2.78 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '4.2 mm/0.17 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes',
            value: '163.5 mm/6.44 in'
          },
          {
            id: 'G',
            title: 'Orientation angle',
            value: '45°'
          }
        ],
        photo: ['123', '123', '123', '123']
      },
      {
        name: 'Ruby321',
        bannerData: {
          title: 'Ruby Performance 6.5adasd',
          text: 'some 111text banner',
          image: {
            small: '',
            medium: ''
          },
          productTheme: 'black'
        },
        techSpecs: [
          {
            id: '0',
            title: 'Nominal impedance',
            value: '4 ohms'
          },
          {
            id: '1',
            title: 'Voice coil winding material',
            value: 'copper wire'
          },
          {
            id: '2',
            title: 'Effective radiation area',
            value: '144 cm²'
          },
          {
            id: '3',
            title: 'Power handling',
            value: '200 W (HPF 18 dB/oct. on 160 Hz)'
          },
          {
            id: '4',
            title: 'The range of frequencies to reproduce',
            value: '160Hz – 10kHz'
          },
          {
            id: '5',
            title: 'Magnet material',
            value: 'hybrid magnet'
          },
          {
            id: '6',
            title: 'Magnetic core material',
            value: 'electrical steel'
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '173.5 mm/6.83 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '151 mm/5.95 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '12.5 mm/0.50 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '70,5 mm/2.78 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '4.2 mm/0.17 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes',
            value: '163.5 mm/6.44 in'
          },
          {
            id: 'G',
            title: 'Orientation angle',
            value: '45°'
          }
        ],
        photo: ['123', '123', '123', '123']
      }
    ]
  }
];

export const speakerSetData: ItemProductType[] = [
  {
    name: 'Entry',
    icon: M12Icon,
    title: 'Entry',
    text: 'ENTRY level of building an audio system, the initial steps towards enjoying music…',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  },
  {
    name: 'Harmomy',
    icon: M12Icon,
    title: 'Harmomy',
    text: 'HARMONY in the soul, harmony in music - this is what our acoustics gives to every listener.',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'white'
  }
];

export const speakersData: ItemProductType[] = [
  {
    name: 'Ruby',
    icon: M12Icon,
    title: 'Ruby',
    text: 'Ruby - clean and shiny',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  },
  {
    name: 'Solo',
    icon: M12Icon,
    title: 'Solo',
    text: 'It"s your solo competition, when you need to surpass only yourself',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  },
  {
    name: 'Performance',
    icon: M12Icon,
    title: 'Performance',
    text: 'When PERFORMANCE is the main goal, all you need is the P line',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  }
];

export const tweetersData: ItemProductType[] = [
  {
    name: 'HyperTone',
    icon: M12Icon,
    title: 'Hyper Tone',
    text: 'High tone? Nope. Hyper tone! You get it when you need it.',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  }
];
