export const Paths = {
  MAIN: '/',
  PRODUCT: 'product/:name',
  SUBWOOFERS: 'catalog/subwoofers',
  SUBWOOFER_PRODUCT: 'catalog/subwoofers/:name',
  ACOUSTICS: 'acoustics',
  ACOUSTICS_CATEGORY: 'acoustics/:category',
  AMPLIFIERS: 'amplifiers',
  AMPLIFIERS_CATEGORY: 'amplifiers/:category',
  CABLES: 'cables',
  ACCESSORIES: 'accessories',
  PROMO: 'promo',
  PRODUCT_OVERVIEW: 'overview',
  PRODUCT_ACCESSORIES: 'accessories',
  PRODUCT_RELATED: 'related',
  PRODUCT_COMPARE: 'compare',
  PRODUCT_TECHSPECS: 'techspecs',
  CATALOG: 'catalog',
  CATALOG_SECTION: 'catalog/:section',
  CATALOG_CATEGORY: 'catalog/:section/:category',
  CATALOG_MODEL: 'catalog/:section/:category/:name',
  NOT_FOUND: '*'
} as const;
