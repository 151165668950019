import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import LoaderLayout from '../components/LoaderLayout/LoaderLayout';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="app-content">
        <LoaderLayout>
          <Outlet />
        </LoaderLayout>
      </div>
      <Footer />
    </div>
  );
}

export default App;
