import M9Icon from '../assets/icons/subwoofers/m9.svg';
import { ItemProductType } from './subwoofersData';
import { IAcousticsType } from './acousticsData';

export const newArrayAmplifiers: IAcousticsType[] = [
  {
    id: '1',
    type: 'Сабвуферные УМ',
    bannerData: {
      title: 'Сабвуферные УМ',
      text: 'Сабвуферные УМ text',
      image: {
        small: '',
        medium: ''
      },
      productTheme: 'black'
    },
    models: [
      {
        name: 'UNO',
        bannerData: {
          title: 'UNO',
          text: 'UNO some text',
          image: {
            small: '',
            medium: ''
          },
          productTheme: 'black'
        },
        techSpecs: [
          {
            id: '0',
            title: 'Мощность при 4 Ом',
            value: '160 Вт'
          },
          {
            id: '1',
            title: 'Мощность при 2 Ом',
            value: '280 Вт'
          },
          {
            id: '2',
            title: 'Мощность при 1 Ом',
            value: '400 Вт'
          },
          {
            id: '3',
            title: 'Частотная характеристика',
            value: '15-270 Гц'
          },
          {
            id: '4',
            title: 'Отношение сигнал/шум',
            value: '85 дБ'
          },
          {
            id: '5',
            title: 'КПД при 4 Ом',
            value: 'Более 90%'
          },
          {
            id: '6',
            title: 'Коэффициент демпфирования',
            value: '> 200'
          },
          {
            id: '7',
            title: 'Чувствительность на входе',
            value: '8В-0,3В'
          },
          {
            id: '8',
            title: 'Инфразвуковой фильтр',
            value: '10-50 Гц'
          },
          {
            id: '9',
            title: 'Подъем нижних частот',
            value: '0-9 дБ'
          },
          {
            id: '10',
            title:
              'Дистанционный регулятор с индикацией превышения макс уровня искажений (5% К.Н.И.)',
            value: 'Включено'
          },
          {
            id: '11',
            title: 'Фильтр низких частот',
            value: '35-250 Гц'
          },
          {
            id: '12',
            title: 'Фаза',
            value: 'Нет'
          },
          {
            id: '13',
            title: 'Каскадное соединение',
            value: 'Нет'
          },
          {
            id: '14',
            title: 'Ток предохранителя',
            value: '40 А'
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'Длина',
            value: '200 мм'
          },
          {
            id: 'B',
            title: 'Высота',
            value: '52 мм'
          },
          {
            id: 'C',
            title: 'Ширина',
            value: '225 мм'
          },
          {
            id: 'D',
            title: 'Цвет',
            value: 'Черный'
          },
          {
            id: 'E',
            title: 'Вес изделия',
            value: '2 кг'
          }
        ],
        photo: []
      }
    ]
  }
];

export const amplifiersData: ItemProductType[] = [
  {
    name: 'Uno series',
    icon: M9Icon,
    title: 'Uno series',
    text: 'Tune your system and shake all around you',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  },
  {
    name: 'Due Series',
    icon: M9Icon,
    title: '«Due Series',
    text: 'The optimal choice for the first audio step',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  },
  {
    name: 'Quattro series',
    icon: M9Icon,
    title: '«Quattro series',
    text: 'Orchestra is closer than you"re thinking...',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  },
  {
    name: 'FR series',
    icon: M9Icon,
    title: 'FR series',
    text: 'Digital technology and crystal sound. Made for the loudest systems.',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  },
  {
    name: 'Competition series',
    icon: M9Icon,
    title: 'Competition series',
    text: 'Searching for the dB and extreme SPL? We have the solution!',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  },
  {
    name: 'Apex series',
    icon: M9Icon,
    title: 'Apex series',
    text: 'We used all our experience to create unbelievable products. Feel the best solution for uncompromised pure sound and power',
    image: {
      small: '',
      medium: ''
    },
    productTheme: 'black'
  }
];
