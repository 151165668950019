import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import ThemeProvider from './app/providers/ThemeProvider/ThemeProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from './app/providers/router/AppRouter';
import './app/styles/index.scss';
import './i18n';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider>
          <Suspense fallback={''}>
            <RouterProvider router={router} />
          </Suspense>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}
