import React, { ReactNode, useEffect } from 'react';
import cls from './HeadNavLinks.module.scss';
import { NavLink, useLocation, useMatch, useMatches } from 'react-router-dom';

interface Props {
  children: ReactNode;
  to: string;
  ind: any;
  underlineSize: any;
  handleClickLink: (i: any) => void;
  setSelectedIndex: (i: any) => void;
}

export const CustomLink = ({
  children,
  to,
  ind,
  underlineSize,
  handleClickLink,
  setSelectedIndex
}: Props) => {
  const match = useMatch(`${to}/*`);

  useEffect(() => {
    match && setSelectedIndex(ind);
  }, [match]);

  return (
    <NavLink
      to={to}
      className={cls.navLink}
      onClick={() => handleClickLink(ind)}
      style={{ width: `${underlineSize}%` }}
    >
      {children}
    </NavLink>
  );
};
