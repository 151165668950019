import React from 'react';
import cls from './Footer.module.scss';
import { WidthLimiterPadding } from '../WidthLimiter';
import { ReactComponent as LogoPride } from '../../assets/icons/logo.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { newTestArraySub } from '../../testData/subwoofersData';
import {
  speakersData,
  speakerSetData,
  tweetersData
} from '../../testData/acousticsData';
import { amplifiersData } from '../../testData/amplifiersData';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <WidthLimiterPadding>
        <div className={cls.Footer}>
          <div className={cls.topFooter}>
            <div className={cls.column}>
              <h3>{t('navSubwoofers')}</h3>
              <div className={cls.colList}>
                {newTestArraySub.map((item) => (
                  <Link to={''} key={item.model} className={cls.listLink}>
                    {item.model}
                  </Link>
                ))}
              </div>
            </div>
            <div className={cls.column}>
              <h3>{t('navAcoustics')}</h3>
              <div className={cls.colList}>
                {speakerSetData.map((item) => (
                  <Link to={''} key={item.name} className={cls.listLink}>
                    {item.name}
                  </Link>
                ))}
                {speakersData.map((item) => (
                  <Link to={''} key={item.name} className={cls.listLink}>
                    {item.name}
                  </Link>
                ))}
                {tweetersData.map((item) => (
                  <Link to={''} key={item.name} className={cls.listLink}>
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className={cls.column}>
              <h3>{t('navAmplifiers')}</h3>
              <div className={cls.colList}>
                {amplifiersData.map((item) => (
                  <Link to={''} key={item.name} className={cls.listLink}>
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className={cls.column}>
              <h3>{t('navCables')}</h3>
              <div className={cls.colList}>
                {speakerSetData.map((item) => (
                  <Link to={''} key={item.name} className={cls.listLink}>
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className={cls.bottomFooter}>
            <LogoPride className={cls.logoPride} />
            <div className={cls.copyright}>
              Copyright 2023, Pride. All Rights Reserved.
            </div>
            <div className={cls.pdfLinks}>
              <a href="">Privacy Policy</a>
              <a href="">Terms of Use</a>
            </div>
          </div>
        </div>
      </WidthLimiterPadding>
    </footer>
  );
};
