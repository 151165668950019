import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../app/providers/router/paths';
import { useTranslation } from 'react-i18next';
import { WidthLimiterPadding } from '../WidthLimiter';
import { CustomLink } from './CustomLink';
import cls from './HeadNavLinks.module.scss';

interface HeadNavLinksProps {
  showMenu: boolean;
  onCloseMenu: () => void;
}

const withDefaultIndex = (a: any, def = 0) => (!-~a ? def : a);

export const HeadNavLinks = (props: HeadNavLinksProps) => {
  const { showMenu, onCloseMenu } = props;
  const { t, i18n } = useTranslation();
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const underlineSize = 100 / 4;
  const underlineIdx = withDefaultIndex(hoverIndex, selectedIndex);
  const underlineShift = underlineIdx * underlineSize;

  const handleClickLink = (i: any) => {
    setSelectedIndex(i);
    onCloseMenu();
  };

  return (
    <nav className={showMenu ? `${cls.navBar} ${cls.show}` : cls.navBar}>
      <ul className={cls.linkList}>
        <li
          className={cls.hoverLinkContainer}
          onMouseEnter={() => setHoverIndex(0)}
          onMouseLeave={() => setHoverIndex(-1)}
        >
          <CustomLink
            to={Paths.SUBWOOFERS}
            ind={0}
            handleClickLink={handleClickLink}
            underlineSize={underlineSize}
            setSelectedIndex={setSelectedIndex}
          >
            {t('navSubwoofers')}
          </CustomLink>
          <div className={cls.subMenu}>
            <WidthLimiterPadding>
              <div className={cls.subContainer}>
                <div className={cls.subCol}>
                  <h4>Choose your own sound</h4>
                  <ul>
                    <li>
                      <Link
                        className={cls.subLinkBold}
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        M.6
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={cls.subLinkBold}
                        to={`${Paths.SUBWOOFERS}/M.9/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        M.9
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={cls.subLinkBold}
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        M.6
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={cls.subLinkBold}
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        M.6
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={cls.subLinkBold}
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        M.6
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={cls.subCol}>
                  <h4>Introduction</h4>
                  <ul>
                    <li onClick={() => setSelectedIndex(0)}>
                      <Link
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        Explorer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${Paths.SUBWOOFERS}/M.9/${Paths.PRODUCT_OVERVIEW}`}
                        onClick={() => setSelectedIndex(0)}
                      >
                        Compare models
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        Why PRIDE
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        How to tune
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </WidthLimiterPadding>
          </div>
        </li>
        <li
          className={cls.hoverLinkContainer}
          onMouseEnter={() => setHoverIndex(1)}
          onMouseLeave={() => setHoverIndex(-1)}
        >
          <CustomLink
            to={`${Paths.CATALOG}/${Paths.ACOUSTICS}`}
            ind={1}
            handleClickLink={handleClickLink}
            underlineSize={underlineSize}
            setSelectedIndex={setSelectedIndex}
          >
            {t('navAcoustics')}
          </CustomLink>
          <div className={cls.subMenu}>
            <WidthLimiterPadding>
              <div className={cls.subContainer}>
                <div className={cls.subCol}>
                  <h4>Categories</h4>
                  <ul>
                    <li>
                      <Link
                        className={cls.subLinkBold}
                        to={`${Paths.CATALOG}/${Paths.ACOUSTICS}/СЧ`}
                      >
                        СЧ
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={cls.subLinkBold}
                        to={`${Paths.CATALOG}/${Paths.ACOUSTICS}/СЧ`}
                      >
                        СЧ
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={cls.subLinkBold}
                        to={`${Paths.CATALOG}/${Paths.ACOUSTICS}/СЧ`}
                      >
                        СЧ
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={cls.subCol}>
                  <h4>Introduction</h4>
                  <ul>
                    <li onClick={() => setSelectedIndex(0)}>
                      <Link
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        Explorer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${Paths.SUBWOOFERS}/M.9/${Paths.PRODUCT_OVERVIEW}`}
                        onClick={() => setSelectedIndex(0)}
                      >
                        Compare models
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        Why PRIDE
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${Paths.SUBWOOFERS}/M.6/${Paths.PRODUCT_OVERVIEW}`}
                      >
                        How to tune
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </WidthLimiterPadding>
          </div>
        </li>
        <li
          className={cls.hoverLinkContainer}
          onMouseEnter={() => setHoverIndex(2)}
          onMouseLeave={() => setHoverIndex(-1)}
        >
          <CustomLink
            to={`${Paths.CATALOG}/${Paths.AMPLIFIERS}`}
            ind={2}
            handleClickLink={handleClickLink}
            underlineSize={underlineSize}
            setSelectedIndex={setSelectedIndex}
          >
            {t('navAmplifiers')}
          </CustomLink>
        </li>
        <li
          className={cls.hoverLinkContainer}
          onMouseEnter={() => setHoverIndex(3)}
          onMouseLeave={() => setHoverIndex(-1)}
        >
          <CustomLink
            to={Paths.CABLES}
            ind={3}
            handleClickLink={handleClickLink}
            underlineSize={underlineSize}
            setSelectedIndex={setSelectedIndex}
          >
            {t('navCables')}
          </CustomLink>
        </li>
        <hr
          style={{
            marginLeft: `${underlineShift}%`,
            width: `${underlineSize}%`,
            opacity: `${selectedIndex < 0 && hoverIndex < 0 ? '0' : '1'}`
          }}
        />
      </ul>
    </nav>
  );
};
