import React, { useState } from 'react';
import { ReactComponent as LogoPride } from '../../assets/icons/logo.svg';
import { ReactComponent as BurgerIcon } from '../../assets/icons/menu-hamburger.svg';
import { ReactComponent as BurgerIconClose } from '../../assets/icons/menu-hamburger-close.svg';
import { useTheme } from '../../app/providers/ThemeProvider/useTheme';
import { Link } from 'react-router-dom';
import { Paths } from '../../app/providers/router/paths';
import { Theme } from '../../app/providers/ThemeProvider/ThemeContext';
import { useTranslation } from 'react-i18next';
import cls from './Header.module.scss';
import { WidthLimiterPadding } from '../WidthLimiter';
import { HeadNavLinks } from './HeadNavLinks';

export const Header = () => {
  const { theme, toggleTheme } = useTheme();
  const { t, i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu((prevState) => !prevState);
  };

  const onCloseMenu = () => {
    setShowMenu(false);
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <header className={cls.header}>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <Link to={Paths.MAIN} className={cls.logo} reloadDocument={true}>
            <LogoPride className={cls.logoPride} />
          </Link>
          <HeadNavLinks showMenu={showMenu} onCloseMenu={onCloseMenu} />
          <div className={cls.buttons}>
            <div className={cls.languages}>
              {i18n.language === 'en' ? (
                <button onClick={() => changeLanguage('ru')}>РУ</button>
              ) : (
                <button onClick={() => changeLanguage('en')}>EN</button>
              )}
            </div>
            {/*<button onClick={toggleTheme}>
              {theme === Theme.LIGHT ? "DARK" : "LIGHT"}
            </button>*/}
            <button onClick={handleShowMenu} className={cls.btnNav}>
              {showMenu ? (
                <BurgerIconClose className={cls.burgerIcon} />
              ) : (
                <BurgerIcon className={cls.burgerIcon} />
              )}
            </button>
          </div>
        </div>
      </WidthLimiterPadding>
    </header>
  );
};
