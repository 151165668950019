import M6Icon from '../assets/icons/subwoofers/m6.svg';
import M9Icon from '../assets/icons/subwoofers/m9.svg';
import M12Icon from '../assets/icons/subwoofers/m12.svg';
import M16Icon from '../assets/icons/subwoofers/m16.svg';
import M20Icon from '../assets/icons/subwoofers/m20.svg';
import M25Icon from '../assets/icons/subwoofers/m25.svg';
import M35Icon from '../assets/icons/subwoofers/m35.svg';
import M45Icon from '../assets/icons/subwoofers/m45.svg';

import m6small from '../assets/images/subwoofers/hero-m6min.png';
import m6medium from '../assets/images/subwoofers/hero-m6.png';
import m9small from '../assets/images/subwoofers/hero-m9small.png';
import m9medium from '../assets/images/subwoofers/hero-m9.png';
import m12small from '../assets/images/subwoofers/hero-m12small.png';
import m12medium from '../assets/images/subwoofers/hero-m12.png';
import m16small from '../assets/images/subwoofers/hero-m16small.png';
import m16medium from '../assets/images/subwoofers/hero-m16.png';
import m20small from '../assets/images/subwoofers/hero-m20small.png';
import m20medium from '../assets/images/subwoofers/hero-m20.png';
import m25small from '../assets/images/subwoofers/hero-m25small.png';
import m25medium from '../assets/images/subwoofers/hero-m25.png';
import m35small from '../assets/images/subwoofers/hero-m35small.png';
import m35medium from '../assets/images/subwoofers/hero-m35.png';
import m45small from '../assets/images/subwoofers/hero-m45small.png';
import m45medium from '../assets/images/subwoofers/hero-m45.png';

import schemem6_10_1 from '../assets/images/subwoofers/M6-10/schemeM6-10-1.svg';
import schemem6_10_2 from '../assets/images/subwoofers/M6-10/schemeM6-10-2.svg';
import schemem6_12_1 from '../assets/images/subwoofers/M6-12/schemeM6-12-1.svg';
import schemem6_12_2 from '../assets/images/subwoofers/M6-12/schemeM6-12-2.svg';
import schemem9_10_1 from '../assets/images/subwoofers/M9-10/schemeM9-10-1.svg';
import schemem9_10_2 from '../assets/images/subwoofers/M9-10/schemeM9-10-2.svg';
import schemem9_12_1 from '../assets/images/subwoofers/M9-12/schemeM9-12-1.svg';
import schemem9_12_2 from '../assets/images/subwoofers/M9-12/schemeM9-12-2.svg';
import schemem9_15_1 from '../assets/images/subwoofers/M9-15/schemeM9-15-1.svg';
import schemem9_15_2 from '../assets/images/subwoofers/M9-15/schemeM9-15-2.svg';

import { ItemSpecsType } from './acousticsData';
import { BannerData } from './allData';

export const newTestArraySub: ISubwoofer[] = [
  {
    id: 'm6',
    model: 'M.6 series',
    icon: M6Icon,
    bannerData: {
      title: 'M.6 series',
      text: 'M.6 series',
      image: {
        small: m6small,
        medium: m6medium
      },
      productTheme: 'black'
    },
    variant: [
      {
        id: '10',
        name: '10',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '600 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '1.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '5.3'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '35.5'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '168'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '4.6'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.54'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.6'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.15'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '18.1'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '15'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '84.1'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '337'
              }
            ]
          },
          {
            name: 'D1.7',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '600 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '1.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '5.3'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.7+1.7 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '36.1'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '163'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '13.8'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.52'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.58'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.1'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '18.5'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '15'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '84.3'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '337'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '265 mm / 10.44 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '231 mm / 9.1 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '25 mm / 0.99 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '131 mm / 5.16 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.3 mm / 0.21 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '248 mm / 9.77 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '15°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '15°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam6_10_1', 'schemam6_10_2'],
        photo: [
          '/images/subwoofers/M.6-10/Photo/01.Top.png',
          '/images/subwoofers/M.6-10/Photo/02.Front.png',
          '/images/subwoofers/M.6-10/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.6-10/Photo/04.Perspective.png',
          '/images/subwoofers/M.6-10/Photo/05.Close_Top.png',
          '/images/subwoofers/M.6-10/Photo/06.Contacts_Close.png'
        ]
      },
      {
        id: '12',
        name: '12',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '600 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '5.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '35'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '210'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '4.6'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.58'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.65'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.28'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '38.5'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '15'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86.6'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '507'
              }
            ]
          },
          {
            name: 'D1.7',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '600 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '5.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.7+1.7 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '34.1'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '205'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '13.9'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.56'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.63'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.32'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '38.2'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '15'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86.8'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '507'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '316 mm / 12.44 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '279 mm / 10.99 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '25 mm / 0.99 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '142.5 mm / 5.61 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.3 mm / 0.21 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '298 mm / 11.74 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '15°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '15°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam6_12_1', 'schemam6_12_2'],
        photo: [
          '/images/subwoofers/M.6-12/Photo/01.Top.png',
          '/images/subwoofers/M.6-12/Photo/02.Front.png',
          '/images/subwoofers/M.6-12/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.6-12/Photo/04.Perspective.png',
          '/images/subwoofers/M.6-12/Photo/05.Close_Top.png',
          '/images/subwoofers/M.6-12/Photo/06.Contacts_Close.png',
          '/images/subwoofers/M.6-12/Photo/07.Free_Angle.png'
        ]
      }
    ]
  },
  {
    id: 'm9',
    model: 'M.9 series',
    icon: M9Icon,
    bannerData: {
      title: 'M.9 series',
      text: 'M.9 series',
      image: {
        small: m9small,
        medium: m9medium
      },
      productTheme: 'black'
    },
    variant: [
      {
        id: '10',
        name: '10',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '900 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.5" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '7.7'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '32.5'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '212'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '5.3'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.57'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.64'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.25'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '17.4'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '17'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '83.9'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '337'
              }
            ]
          },
          {
            name: 'D1.8',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '900 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.5" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '7.7'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.8+1.8 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '33.6'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '205'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '15.9'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.58'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.65'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.15'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '17.2'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '17'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '84.1'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '337'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '275 mm / 10.83 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '242 mm / 9.53 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '19 mm / 0.75 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '149 mm / 5.87 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '258 mm / 10.15 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '-'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam9_10_1', 'schemam9_10_2'],
        photo: [
          '/images/subwoofers/M.9-10/Photo/01.Top.png',
          '/images/subwoofers/M.9-10/Photo/02.Front.png',
          '/images/subwoofers/M.9-10/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.9-10/Photo/04.Perspective.png',
          '/images/subwoofers/M.9-10/Photo/05.Close_Top.png',
          '/images/subwoofers/M.9-10/Photo/06.Contacts_Close.png'
        ]
      },
      {
        id: '12',
        name: '12',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '900 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.5" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '7.9'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '32.8'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '252'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '5.3'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.61'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.69'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.26'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '31.8'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '17'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '85.3'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '465'
              }
            ]
          },
          {
            name: 'D1.8',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '900 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.5" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '7.9'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.8+1.8 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '33.4'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '247'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '16'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.62'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.7'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.22'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '32.2'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '17'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '85.5'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '465'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '316 mm / 12.44 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '280 mm / 11.03 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '19 mm / 0.75 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '152.5 mm / 6 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '298 mm / 11.74 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '-'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam9_12_1', 'schemam9_12_2'],
        photo: [
          '/images/subwoofers/M.9-12/Photo/01.Top.png',
          '/images/subwoofers/M.9-12/Photo/02.Front.png',
          '/images/subwoofers/M.9-12/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.9-12/Photo/04.Perspective.png',
          '/images/subwoofers/M.9-12/Photo/05.Close_Top.png',
          '/images/subwoofers/M.9-12/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.9-12/Photo/07.Contacts_Close_2.png'
        ]
      },
      {
        id: '15',
        name: '15',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '900 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.5" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '3.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '8'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '31.2'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '320'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '5.2'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.66'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.75'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.5'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '68.7'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '17'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86.9'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '774'
              }
            ]
          },
          {
            name: 'D1.8',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '900 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.5" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '3.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '8'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.8+1.8 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '31.6'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '312'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '15.9'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.67'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.76'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.66'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '68.5'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '17'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '87.1'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '774'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '390 mm / 15.36 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '352 mm / 13.86 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '20 mm / 0.79 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '179 mm / 7.05 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '372 mm / 14.65 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '-'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam9_15_1', 'schemam9_15_2'],
        photo: [
          '/images/subwoofers/M.9-15/Photo/01.Top.png',
          '/images/subwoofers/M.9-15/Photo/02.Front.png',
          '/images/subwoofers/M.9-15/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.9-15/Photo/04.Perspective.png',
          '/images/subwoofers/M.9-15/Photo/05.Close_Top.png',
          '/images/subwoofers/M.9-15/Photo/06.Contacts_Close.png'
        ]
      }
    ]
  },
  {
    id: 'm12',
    model: 'M.12 series',
    icon: M12Icon,
    bannerData: {
      title: 'M.12 series',
      text: 'M.12 series',
      image: {
        small: m12small,
        medium: m12medium
      },
      productTheme: 'black'
    },
    variant: [
      {
        id: '10',
        name: '10',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1200 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.8" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '10'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '32.6'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '249'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '6.2'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.49'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.54'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.1'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '16.1'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '19'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '83.5'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '337'
              }
            ]
          },
          {
            name: 'D1.8',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1200 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.8" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '10'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.8+1.8 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '33.1'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '238'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '18.6'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.48'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.53'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.2'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '16.5'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '19'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '83.7'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '337'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '275 mm / 10.83 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '242 mm / 9.53 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '19 mm / 0.75 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '157 mm / 6.19 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '258 mm / 10.15 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '-'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam12_10_1', 'schemam12_10_2'],
        photo: [
          '/images/subwoofers/M.12-10/Photo/01.Top.png',
          '/images/subwoofers/M.12-10/Photo/02.Front.png',
          '/images/subwoofers/M.12-10/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.12-10/Photo/04.Perspective.png',
          '/images/subwoofers/M.12-10/Photo/05.Close_Top.png',
          '/images/subwoofers/M.12-10/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.12-10/Photo/06.Contacts_Close_2.png',
          '/images/subwoofers/M.12-10/Photo/06.Contacts_Close_3.png',
          '/images/subwoofers/M.12-10/Photo/07.Free_Angle.png'
        ]
      },
      {
        id: '12',
        name: '12',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1200 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.8" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '10.3'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '32.1'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '285'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '6.2'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.52'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.58'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.2'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '27.7'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '19'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '84.7'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '465'
              }
            ]
          },
          {
            name: 'D1.8',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1200 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.8" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '2.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '10.3'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.8+1.8 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '33.5'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '278'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '18.6'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.5'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.56'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.1'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '27.5'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '19'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '84.8'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '465'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '316 mm / 12.44 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '280 mm / 11.03 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '19 mm / 0.75 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '161 mm / 6.34 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '298 mm / 11.74 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '-'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam12_12_1', 'schemam12_12_2'],
        photo: [
          '/images/subwoofers/M.12-12/Photo/01.Top.png',
          '/images/subwoofers/M.12-12/Photo/02.Front.png',
          '/images/subwoofers/M.12-12/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.12-12/Photo/04.Perspective.png',
          '/images/subwoofers/M.12-12/Photo/05.Close_Top.png',
          '/images/subwoofers/M.12-12/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.12-12/Photo/06.Contacts_Close_2.png'
        ]
      },
      {
        id: '15',
        name: '15',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1200 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.8" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '3.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '10.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '29.2'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '325'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '6.3'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.64'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.73'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.1'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '57.5'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '19'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86.7'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '774'
              }
            ]
          },
          {
            name: 'D1.8',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1200 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '2.8" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'steel'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '3.5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '10.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.8+1.8 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '29.8'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '321'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '18.7'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.63'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.72'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.2'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '58.1'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '19'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86.9'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '774'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '390 mm / 15.36 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '352 mm / 13.86 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '20 mm / 0.79 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '181 mm / 7.05 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '372 mm / 14.65 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '-'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam12_15_1', 'schemam12_15_2'],
        photo: [
          '/images/subwoofers/M.12-15/Photo/01.Top.png',
          '/images/subwoofers/M.12-15/Photo/02.Front.png',
          '/images/subwoofers/M.12-15/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.12-15/Photo/04.Perspective.png',
          '/images/subwoofers/M.12-15/Photo/05.Close_Top.png',
          '/images/subwoofers/M.12-15/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.12-15/Photo/06.Contacts_Close_2.png',
          '/images/subwoofers/M.12-15/Photo/06.Contacts_Close_3.png',
          '/images/subwoofers/M.12-15/Photo/07.Free_Angle.png'
        ]
      }
    ]
  },
  {
    id: 'm16',
    model: 'M.16 series',
    icon: M16Icon,
    bannerData: {
      title: 'M.16 series',
      text: 'M.16 series',
      image: {
        small: m16small,
        medium: m16medium
      },
      productTheme: 'black'
    },
    variant: [
      {
        id: '12',
        name: '12',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1600 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 190*25 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'hi-temp ABS'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '4'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '13.3'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '26.8'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '399'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '7.2'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.44'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.49'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '4.74'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '34.8'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '21'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '83.7'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1600 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 190*25 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'hi-temp ABS'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '4'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '13.3'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.5+1.5 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '27'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '406'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '21.5'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.43'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.47'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '4.82'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '36.1'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '21'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '83.8'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '325 mm / 12.8 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '308 mm / 12.13 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '32.5 mm / 1.28 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '188 mm / 7.4 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.2 mm / 0.21 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '332 mm / 13.07 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '4.6°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam16_12_1', 'schemam16_12_2'],
        photo: [
          '/images/subwoofers/M.16-12/Photo/01.Top.png',
          '/images/subwoofers/M.16-12/Photo/02.Front.png',
          '/images/subwoofers/M.16-12/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.16-12/Photo/04.Perspective.png',
          '/images/subwoofers/M.16-12/Photo/05.Close_Top.png',
          '/images/subwoofers/M.16-12/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.16-12/Photo/06.Contacts_Close_2.png',
          '/images/subwoofers/M.16-12/Photo/06.Contacts_Close_3.png',
          '/images/subwoofers/M.16-12/Photo/06.Contacts_Close_4.png'
        ]
      },
      {
        id: '15',
        name: '15',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1600 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 190*25 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'hi-temp ABS'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '13.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '26.1'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '470'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '7.3'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.46'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.49'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '4.85'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '65.2'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '21'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '845'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '1600 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 190*25 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'hi-temp ABS'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '13.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.5+1.5 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '26.6'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '476'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '21.7'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.45'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.48'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '4.88'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '64.3'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '21'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86.2'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '845'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '397 mm / 15.63 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '370 mm / 14.57 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '32.5 mm / 1.28 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '200 mm / 7.87 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.2 mm / 0.21 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '402 mm / 15.83 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '20.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '4°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam16_15_1', 'schemam16_15_2'],
        photo: [
          '/images/subwoofers/M.16-15/Photo/01.Top.png',
          '/images/subwoofers/M.16-15/Photo/02.Front.png',
          '/images/subwoofers/M.16-15/Photo/03.Front_Contacts_1.png',
          '/images/subwoofers/M.16-15/Photo/03.Front_Contacts_2.png',
          '/images/subwoofers/M.16-15/Photo/04.Perspective.png',
          '/images/subwoofers/M.16-15/Photo/05.Close_Top.png',
          '/images/subwoofers/M.16-15/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.16-15/Photo/06.Contacts_Close_2.png',
          '/images/subwoofers/M.16-15/Photo/06.Contacts_Close_3.png'
        ]
      }
    ]
  },
  {
    id: 'm20',
    model: 'M.20 series',
    icon: M20Icon,
    bannerData: {
      title: 'M.20 series',
      text: 'M.20 series',
      image: {
        small: m20small,
        medium: m20medium
      },
      productTheme: 'black'
    },
    variant: [
      {
        id: '12',
        name: '12',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '2000 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 210*27 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'hi-temp ABS'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '4'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '18.2'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '29.5'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '410'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '8.7'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.31'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.34'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '4.31'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '33.2'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '23'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '85.7'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          },
          {
            name: 'D1.6',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '2000 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 210*27 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'hi-temp ABS'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '4'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '18.2'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.6+1.6 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '29.1'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '402'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '26.1'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.29'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.31'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '4.26'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '32.8'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '23'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86.1'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '325 mm / 12.8 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '308 mm / 12.13 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '32.5 mm / 1.28 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '188 mm / 7.4 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.2 mm / 0.21 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '332 mm / 13.07 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '4.6°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam20_12_1', 'schemam20_12_2'],
        photo: [
          '/images/subwoofers/M.20-12/Photo/01.Top.png',
          '/images/subwoofers/M.20-12/Photo/02.Front.png',
          '/images/subwoofers/M.20-12/Photo/03.Front_Contacts_1.png',
          '/images/subwoofers/M.20-12/Photo/03.Front_Contacts_2.png',
          '/images/subwoofers/M.20-12/Photo/04.Perspective.png',
          '/images/subwoofers/M.20-12/Photo/05.Close_Top.png',
          '/images/subwoofers/M.20-12/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.20-12/Photo/06.Contacts_Close_2.png',
          '/images/subwoofers/M.20-12/Photo/06.Contacts_Close_3.png'
        ]
      },
      {
        id: '15',
        name: '15',
        price: '3000',
        coil: [
          {
            name: 'S0.4',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '2000 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 210*27 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'hi-temp ABS'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '18.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.4 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '26.7'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '481'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '8.7'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.33'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.35'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '4.76'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '61.7'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '23'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '88.4'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '845'
              }
            ]
          },
          {
            name: 'D1.6',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '2000 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" round copper'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 210*27 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'hi-temp ABS'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '18.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.6+1.6 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '27.2'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '488'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '25.8'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.32'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.34'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '4.75'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '62.3'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '23'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '88.7'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '845'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '397 mm / 15.63 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '370 mm / 14.57 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '32.5 mm / 1.28 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '200 mm / 7.87 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.2 mm / 0.21 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '402 mm / 15.83 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '20.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '4°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam20_15_1', 'schemam20_15_2'],
        photo: [
          '/images/subwoofers/M.20-15/Photo/01.Top.png',
          '/images/subwoofers/M.20-15/Photo/02.Front.png',
          '/images/subwoofers/M.20-15/Photo/03.Front_Contacts_1.png',
          '/images/subwoofers/M.20-15/Photo/03.Front_Contacts_2.png',
          '/images/subwoofers/M.20-15/Photo/04.Perspective.png',
          '/images/subwoofers/M.20-15/Photo/05.Close_Top.png',
          '/images/subwoofers/M.20-15/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.20-15/Photo/06.Contacts_Close_2.png'
        ]
      }
    ]
  },
  {
    id: 'm25',
    model: 'M.25 series',
    icon: M25Icon,
    bannerData: {
      title: 'M.25 series',
      text: 'M.25 series',
      image: {
        small: m25small,
        medium: m25medium
      },
      productTheme: 'black'
    },
    variant: [
      {
        id: '12',
        name: '12',
        price: '3000',
        coil: [
          {
            name: 'D0.7',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '2500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 220*27 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '4'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '21.4'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.75+0.75 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '35.5'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '308'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '15.7'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.39'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.42'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.3'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '27.8'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '34'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '86.8'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '2500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 220*27 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '4'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '21.4'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.5+1.5 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '36.1'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '299'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '22.7'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.38'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.41'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.25'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '28.1'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '34'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '87.1'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '325 mm / 12.8 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '308 mm / 12.13 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '32.5 mm / 1.28 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '197 mm / 7.76 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.2 mm / 0.21 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '332 mm / 13.07 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '4.6°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam25_12_1', 'schemam25_12_2'],
        photo: [
          '/images/subwoofers/M.25-12/Photo/01.Top.png',
          '/images/subwoofers/M.25-12/Photo/02.Front.png',
          '/images/subwoofers/M.25-12/Photo/03.Front_Contacts_1.png',
          '/images/subwoofers/M.25-12/Photo/03.Front_Contacts_2.png',
          '/images/subwoofers/M.25-12/Photo/04.Perspective.png',
          '/images/subwoofers/M.25-12/Photo/05.Close_Top.png',
          '/images/subwoofers/M.25-12/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.25-12/Photo/06.Contacts_Close_2.png'
        ]
      },
      {
        id: '15',
        name: '15',
        price: '3000',
        coil: [
          {
            name: 'D0.7',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '2500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 220*27 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '21.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.75+0.75 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '31.4'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '395'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '15.8'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.44'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.48'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.25'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '71.7'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '34'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '88.7'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '845'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '2500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.2" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '2 x 220*27 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '230 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper with glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '5'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '21.6'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.5+1.5 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '31.7'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '388'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '22.3'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.43'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.47'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '5.28'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '70.8'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '34'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '88.8'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: ''
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '845'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '397 mm / 15.63 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '370 mm / 14.57 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '32.5 mm / 1.28 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '209 mm / 8.23 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.2 mm / 0.21 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '402 mm / 15.83 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '20.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '4°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '-'
          }
        ],
        scheme: ['schemam25_15_1', 'schemam25_15_2'],
        photo: [
          '/images/subwoofers/M.25-15/Photo/01.Top.png',
          '/images/subwoofers/M.25-15/Photo/02.Front.png',
          '/images/subwoofers/M.25-15/Photo/03.Front_Contacts_1.png',
          '/images/subwoofers/M.25-15/Photo/03.Front_Contacts_2.png',
          '/images/subwoofers/M.25-15/Photo/04.Perspective.png',
          '/images/subwoofers/M.25-15/Photo/05.Close_Top.png',
          '/images/subwoofers/M.25-15/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.25-15/Photo/06.Contacts_Close_2.png'
        ]
      }
    ]
  },
  {
    id: 'm35',
    model: 'M.35 series',
    icon: M35Icon,
    bannerData: {
      title: 'M.35 series',
      text: 'M.35 series',
      image: {
        small: m35small,
        medium: m35medium
      },
      productTheme: 'black'
    },
    variant: [
      {
        id: '12',
        name: '12',
        price: '3000',
        coil: [
          {
            name: 'D0.75',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '3500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.6" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '3 x 240*26 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '250 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper reinforced glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '6'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '37.5'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.75+0.75 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '29.3'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '332'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '18'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.242'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.256'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '3.23'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '36.7'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '35'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '87.4'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '92.2'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '3500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.6" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '3 x 240*26 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '250 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper reinforced glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '6'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '37.5'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.5+1.5 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '28.4'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '321'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '25.5'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.236'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.253'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '3.16'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '38.9'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '35'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '87.5'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '92.3'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '325 mm / 12.8 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '307 mm / 12.09 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '31 mm / 1.22 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '260 mm / 10.24 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '327 mm / 12.87 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '24°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '8°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '3.5°'
          }
        ],
        scheme: ['schemam35_12_1', 'schemam35_12_2'],
        photo: [
          '/images/subwoofers/M.35-12/Photo/01.Top.png',
          '/images/subwoofers/M.35-12/Photo/02.Front.png',
          '/images/subwoofers/M.35-12/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.35-12/Photo/04.Perspective.png',
          '/images/subwoofers/M.35-12/Photo/05.Close_Top.png',
          '/images/subwoofers/M.35-12/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.35-12/Photo/06.Contacts_Close_2.png',
          '/images/subwoofers/M.35-12/Photo/06.Contacts_Close_3.png'
        ]
      },
      {
        id: '15',
        name: '15',
        price: '3000',
        coil: [
          {
            name: 'D0.75',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '3500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.6" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '3 x 240*26 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '250 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper reinforced glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '8'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '38.5'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.75+0.75 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '26.9'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '428'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '18.1'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.268'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.288'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '3.78'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '84.2'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '35'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '89.6'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '97.7'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '835'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '3500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '3.6" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '3 x 240*26 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '250 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'paper reinforced glass fiber'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '8'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '38.5'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.5+1.5 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '26.5'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '417'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '25.6'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.259'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.282'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '3.77'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '85'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '35'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '89.8'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '97.9'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '835'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '397 mm / 15.63 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '366 mm / 14.41 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '31 mm / 1.22 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '276 mm / 10.87 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '400 mm / 15.75 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '9°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '3.5°'
          }
        ],
        scheme: ['schemam35_15_1', 'schemam35_15_2'],
        photo: [
          '/images/subwoofers/M.35-15/Photo/01.Top.png',
          '/images/subwoofers/M.35-15/Photo/02.Front.png',
          '/images/subwoofers/M.35-15/Photo/03.Front_Contacts.png',
          '/images/subwoofers/M.35-15/Photo/04.Perspective.png',
          '/images/subwoofers/M.35-15/Photo/05.Close_Top.png',
          '/images/subwoofers/M.35-15/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.35-15/Photo/06.Contacts_Close_2.png'
        ]
      },
      {
        id: '18',
        name: '18',
        price: '3000',
        coil: [
          {
            name: 'D0.75',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '3500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '-'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: '-'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: '-'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '-'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '-'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '-'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '-'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '-'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '-'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '-'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '-'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '-'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '-'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '-'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '-'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '-'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '-'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '3500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '-'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: '-'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: '-'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '-'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '-'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '-'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '-'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '-'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '-'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '-'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '-'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '-'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '-'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '-'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '-'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '-'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '-'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '462 mm / 18.19 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '430 mm / 16.93 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '41 mm / 1.61 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '293 mm / 11.54 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '466 mm / 18.35 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.15°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '7.7°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '3°'
          }
        ],
        scheme: ['schemam35_18_1', 'schemam35_18_2'],
        photo: [
          '/images/subwoofers/M.35-18/Photo/01.Top.png',
          '/images/subwoofers/M.35-18/Photo/02.Front.png',
          '/images/subwoofers/M.35-18/Photo/03.Perspective.png',
          '/images/subwoofers/M.35-18/Photo/04.Close_Top.png',
          '/images/subwoofers/M.35-18/Photo/05.Contacts_Close.png'
        ]
      }
    ]
  },
  {
    id: 'm45',
    model: 'M.45 series',
    icon: M45Icon,
    bannerData: {
      title: 'M.45 series',
      text: 'M.45 series',
      image: {
        small: m45small,
        medium: m45medium
      },
      productTheme: 'black'
    },
    variant: [
      {
        id: '12',
        name: '12',
        price: '3000',
        coil: [
          {
            name: 'D0.75',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '4500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '4" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '3 x 256*26 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '250 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'carbon (real)'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '6'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '42'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.75+0.75 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '27.1'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '360'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '19.7'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.211'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.227'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '3.77'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '37.2'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '36'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '87.1'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '92.1'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '4500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '4" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '3 x 256*26 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '250 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'carbon (real)'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '6'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '42'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.5+1.5 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '27.7'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '352'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '27.7'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.205'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.225'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '3.75'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '37.7'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '36'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '87.2'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '92.2'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '530'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '325 mm / 12.8 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '307 mm / 12.09 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '31 mm / 1.22 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '260 mm / 10.24 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '327 mm / 12.87 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '24°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '8°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '3.5°'
          }
        ],
        scheme: ['schemam45_12_1', 'schemam45_12_2'],
        photo: [
          '/images/subwoofers/M.45-12/Photo/01.Top.png',
          '/images/subwoofers/M.45-12/Photo/02.Front.png',
          '/images/subwoofers/M.45-12/Photo/03.Front_Contacts_1.png',
          '/images/subwoofers/M.45-12/Photo/03.Front_Contacts_2.png',
          '/images/subwoofers/M.45-12/Photo/04.Perspective.png',
          '/images/subwoofers/M.45-12/Photo/05.Close_Top.png',
          '/images/subwoofers/M.45-12/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.45-12/Photo/06.Contacts_Close_2.png',
          '/images/subwoofers/M.45-12/Photo/06.Contacts_Close_3.png'
        ]
      },
      {
        id: '15',
        name: '15',
        price: '3000',
        coil: [
          {
            name: 'D0.75',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '4500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '4" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '3 x 256*26 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '250 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'carbon (real)'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '8'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '43'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '0.75+0.75 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '26.4'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '439'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '19.9'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.233'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.251'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '3.88'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '87.9'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '36'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '90.1'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '98.2'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '840'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '4500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '4" flat aluminum'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: ' polyimide'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: 'aluminum'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '3 x 256*26 mm'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '250 mm'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: 'carbon (real)'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: 'carbon (real)'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '8'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '43'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '1.5+1.5 Ω'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '27.2'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '442'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '27.9'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '0.228'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '0.248'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '3.91'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '88.5'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '36'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '90.2'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '98.3'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '840'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '397 mm / 15.63 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '366 mm / 14.41 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '31 mm / 1.22 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '276 mm / 10.87 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '400 mm / 15.75 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.5°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '9°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '3.5°'
          }
        ],
        scheme: ['schemam45_15_1', 'schemam45_15_2'],
        photo: [
          '/images/subwoofers/M.45-15/Photo/01.Top.png',
          '/images/subwoofers/M.45-15/Photo/02.Front.png',
          '/images/subwoofers/M.45-15/Photo/03.Front_Contacts_1.png',
          '/images/subwoofers/M.45-15/Photo/03.Front_Contacts_2.png',
          '/images/subwoofers/M.45-15/Photo/04.Perspective.png',
          '/images/subwoofers/M.45-15/Photo/05.Close_Top.png',
          '/images/subwoofers/M.45-15/Photo/06.Contacts_Close_1.png',
          '/images/subwoofers/M.45-15/Photo/06.Contacts_Close_2.png',
          '/images/subwoofers/M.45-15/Photo/06.Contacts_Close_3.png'
        ]
      },
      {
        id: '18',
        name: '18',
        price: '3000',
        coil: [
          {
            name: 'D0.75',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '4500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '-'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: '-'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: '-'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '-'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '-'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '-'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '-'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '-'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '-'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '-'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '-'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '-'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '-'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '-'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '-'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '-'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '-'
              }
            ]
          },
          {
            name: 'D1.5',
            techSpecs: [
              {
                id: 'Power Handling (RMS)',
                title: 'Power Handling (RMS)',
                value: '4500 W'
              },
              {
                id: 'VC material/ diameter',
                title: 'VC material/ diameter',
                value: '-'
              },
              {
                id: 'VC wire insulation',
                title: 'VC wire insulation',
                value: '-'
              },
              {
                id: 'Frame material',
                title: 'Frame material',
                value: '-'
              },
              {
                id: 'Magnet size',
                title: 'Magnet size',
                value: '-'
              },
              {
                id: 'Spider diameter, mm',
                title: 'Spider diameter, mm',
                value: '-'
              },
              {
                id: 'Cone material',
                title: 'Cone material',
                value: '-'
              },
              {
                id: 'Dust cap material',
                title: 'Dust cap material',
                value: '-'
              },
              {
                id: 'Driver Displacement, L',
                title: 'Driver Displacement, L',
                value: '-'
              },
              {
                id: 'Net Weight, kg',
                title: 'Net Weight, kg',
                value: '-'
              },
              {
                id: 'DC Resistance (Re)',
                title: 'DC Resistance (Re)',
                value: '-'
              },
              {
                id: 'Free Air Resonance (Fs) Hz',
                title: 'Free Air Resonance (Fs) Hz',
                value: '-'
              },
              {
                id: 'Moving Mass (Mms) g',
                title: 'Moving Mass (Mms) g',
                value: '-'
              },
              {
                id: 'BL Tm',
                title: 'BL Tm',
                value: '-'
              },
              {
                id: 'Total Speaker “Q” (Qts)',
                title: 'Total Speaker “Q” (Qts)',
                value: '-'
              },
              {
                id: 'Electrical Q (Qes)',
                title: 'Electrical Q (Qes)',
                value: '-'
              },
              {
                id: 'Mechanical Q (Qms)',
                title: 'Mechanical Q (Qms)',
                value: '-'
              },
              {
                id: 'Equivalent Compliance volume (Vas) L',
                title: 'Equivalent Compliance volume (Vas) L',
                value: '-'
              },
              {
                id: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                title: 'One-Way Linear Excursion (Xmax) (BL 50%) mm ',
                value: '-'
              },
              {
                id: 'Sensitivity (1 W / 1 m) dB',
                title: 'Sensitivity (1 W / 1 m) dB',
                value: '-'
              },
              {
                id: 'Sensitivity (2.83 V / 1 m) dB',
                title: 'Sensitivity (2.83 V / 1 m) dB',
                value: '-'
              },
              {
                id: 'Effective Piston Area (Sd) cm²',
                title: 'Effective Piston Area (Sd) cm²',
                value: '-'
              }
            ]
          }
        ],
        physicalSpecs: [
          {
            id: 'A',
            title: 'External diameter',
            value: '462 mm / 18.19 in'
          },
          {
            id: 'B',
            title: 'Mounting hole diameter',
            value: '430 mm / 16.93 in'
          },
          {
            id: 'C',
            title: 'Flange mounting height',
            value: '41 mm / 1.61 in'
          },
          {
            id: 'D',
            title: 'Mounting depth',
            value: '293 mm / 11.54 in'
          },
          {
            id: 'E',
            title: 'Fastening hole diameter',
            value: '5.5 mm / 0.22 in'
          },
          {
            id: 'F',
            title: 'Distance between fastening holes F1',
            value: '466 mm / 18.35 in'
          },
          {
            id: 'G',
            title: 'Orientation angle G',
            value: '22.15°'
          },
          {
            id: 'H',
            title: 'Orientation angle H',
            value: '7.7°'
          },
          {
            id: 'I',
            title: 'Orientation angle I',
            value: '3°'
          }
        ],
        scheme: ['schemam45_18_1', 'schemam45_18_2'],
        photo: []
      }
    ]
  }
];

export interface ISubwoofer {
  id: string;
  model: string; // M.6
  icon: any;
  bannerData: BannerData;
  variant: ISubwooferVariant[]; // 10 или 12
}

export interface ISubwooferVariant {
  id: string;
  name: string; // 10 или 12
  price: string;
  coil: ICoilType[]; // тип катушки. S0.4 или D1.7
  scheme: any[];
  photo: any[];
  physicalSpecs: ItemSpecsType[];
}

export interface ICoilType {
  name: string;
  techSpecs: ItemSpecsType[];
}

// не обращай внимания. не везде ещё выпилил.
export interface ItemProductType {
  name: string;
  icon: any;
  title: string;
  text: string;
  image: {
    small: any;
    medium: any;
  };
  productTheme: string;
}
