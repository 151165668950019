import { useContext } from 'react';
import { Theme, ThemeContext } from './ThemeContext';

export function useTheme() {
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    let newTheme: Theme;
    switch (theme) {
      case Theme.DARK:
        newTheme = Theme.LIGHT;
        break;
      case Theme.LIGHT:
        newTheme = Theme.DARK;
        break;
      default:
        newTheme = Theme.DARK;
    }
    setTheme?.(newTheme);
  };

  return { theme, toggleTheme };
}
